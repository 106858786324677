body {
  height: 100%
}

.App {
  //text-align: center;
  height: 100%;
}

.App-logo {
  height: 6vmin;
  pointer-events: none;
  padding-right: 1rem;
}

 input[type="number"] {
   -webkit-appearance: textfield;
   -moz-appearance: textfield;
   //appearance: textfield;
 }
 input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
 }




